<template>
  <div>
    <h2>Сопроводительный паспорт</h2>

    <el-form
      label-position="top"
      :model="accompanyingPassportView"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item
        label="Регистрационный номер (порядковый номер паспорта в журнале регистрации сопроводительных паспортов перевозки отходов производства)"
      >
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Производитель перевозимых отходов производства">
        <el-input></el-input>
      </el-form-item>

      <el-form-item
        label="Собственник перевозимых отходов производства (если он не является производителем)"
      >
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Получатель перевозимых отходов производства">
        <el-select
          clearable
          class="searchPole"
          v-model="accompanyingPassportView.contractor"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Степень опасности"
        >
          <el-option
            v-for="item in contractors"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Перевозчик отходов производства">
        <el-select
          clearable
          class="searchPole"
          v-model="accompanyingPassportView.contractor"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Степень опасности"
        >
          <el-option
            v-for="item in contractors"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <h4>
        Сведения о транспортном средстве, на котором осуществляется перевозка
        отходов производства, перевозимых отходах производства и их упаковке
      </h4>

      <el-form-item label="Дата перевозки отходов производства">
        <el-date-picker
          style="width: 100%"
          value-format="yyyy-MM-dd"
          v-model="accompanyingPassportView.date"
          type="date"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Транспортное средство: марка и регистрационный знак">
        <el-input></el-input>
      </el-form-item>

      <el-form-item
        label="Фамилия, собственное имя, отчество (если таковое имеется) водителя, подпись"
      >
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Код отхода">
        <el-input disabled></el-input>
      </el-form-item>

      <el-form-item label="Степень опасности и класс опасности опасных отходов">
        <el-input disabled></el-input>
      </el-form-item>

      <el-form-item
        label="Количество отходов производства, перевозимых рейсом (тонн (штук))"
      >
        <el-input disabled></el-input>
      </el-form-item>

      <el-form-item label="Тара (упаковка) - наименование">
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Тара (упаковка) - общий вес, тонн">
        <el-input></el-input>
      </el-form-item>

      <el-form-item
        label="Отметка о получении отходов производства - подпись получателя отходов производства"
      >
        <el-input></el-input>
      </el-form-item>

      <el-form-item
        label="Отметка о получении отходов производства - количество полученных отходов производства, тонн (штук)"
      >
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Номер аварийной карточки">
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Собственник отходов производства">
        <el-input></el-input>
      </el-form-item>

      <el-form-item label=" ФИО ">
        <el-input></el-input>
      </el-form-item>

      <el-form-item label="Перевозчик отходов производства ">
        <el-select
          clearable
          class="searchPole"
          v-model="accompanyingPassportView.contractor"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Степень опасности"
        >
          <el-option
            v-for="item in contractors"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label=" ФИО ">
        <el-input></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary">Сохранить</el-button>
        <el-button type="primary">Получить в Excel</el-button>
        <el-button type="danger">Очистить</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "accompanyingPassportView",
  data() {
    return {
      accompanyingPassportView: {
        contractor: null,
        date: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      contractors: "GETLISTCONTRACTORSSELECT",
    }),
  },

  methods: {
    getContractors() {
      this.$store.dispatch("getListContractors");
    },
  },

  mounted() {
    this.getContractors();
  },
};
</script>

<style scoped></style>
